import React, { useState } from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity, TextInput, Picker, Alert, Platform, Image } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { printAsync } from 'expo-print';
import Base64ImageComponent from '../assets/Base64ImageComponent';

const ConfirmOrder = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { customerData, selectedItems } = route.params || {};

  const [invoiceNo, setInvoiceNo] = useState('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('Bank Transfer');
  const [vatPercentage, setVatPercentage] = useState('21');
  const [from] = useState('Shop'); // Static 'Shop' value
  const [source, setSource] = useState('Online searching'); // State for 'Source' field
  const [downpayment, setDownpayment] = useState(''); // State for 'Downpayment' field
  const [servedBy, setServedBy] = useState('Yannick'); // State for 'Served By' field
  const [bagNumber, setBagNumber] = useState(''); // State for 'Bag Number' field
  const [isFullyPaid, setIsFullyPaid] = useState(false); // New state for fully paid flag
  const [orderNotes, setOrderNotes] = useState(''); // State for order notes
  const [isProcessing, setIsProcessing] = useState(false); // State to control button text

  const [fingerSize, setFingerSize] = useState('I don\'t know my ring size');
  const [engravingStyle, setEngravingStyle] = useState('Straight font');
  const [engravingText, setEngravingText] = useState('');
  const formatCurrency = (value) => {
    if (isNaN(value) || value === undefined || value === null) {
      return '0,00';
    }
    return parseFloat(value).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  const totalExcludingVAT = selectedItems.reduce((total, item) => total + (parseFloat(item.price) / (1 + parseFloat(vatPercentage) / 100)), 0).toFixed(2);
  const totalIncludingVAT = selectedItems.reduce((total, item) => total + parseFloat(item.price), 0).toFixed(2);
  const vatAmount = (totalIncludingVAT - totalExcludingVAT).toFixed(2);

  const countryNames = {
    AF: "Afghanistan",
    AX: "Aland Islands",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctica",
    AG: "Antigua and Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia",
    BQ: "Bonaire, Sint Eustatius and Saba",
    BA: "Bosnia and Herzegovina",
    BW: "Botswana",
    BV: "Bouvet Island",
    BR: "Brazil",
    IO: "British Indian Ocean Territory",
    BN: "Brunei Darussalam",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    CV: "Cabo Verde",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    KY: "Cayman Islands",
    CF: "Central African Republic",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CX: "Christmas Island",
    CC: "Cocos (Keeling) Islands",
    CO: "Colombia",
    KM: "Comoros",
    CG: "Congo",
    CD: "Congo (Democratic Republic)",
    CK: "Cook Islands",
    CR: "Costa Rica",
    CI: "Cote d'Ivoire",
    HR: "Croatia",
    CU: "Cuba",
    CW: "Curacao",
    CY: "Cyprus",
    CZ: "Czech Republic",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    SZ: "Eswatini",
    ET: "Ethiopia",
    FK: "Falkland Islands (Malvinas)",
    FO: "Faroe Islands",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    GF: "French Guiana",
    PF: "French Polynesia",
    TF: "French Southern Territories",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GD: "Grenada",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HT: "Haiti",
    HM: "Heard Island and McDonald Islands",
    VA: "Holy See",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran",
    IQ: "Iraq",
    IE: "Ireland",
    IM: "Isle of Man",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KP: "Korea (North)",
    KR: "Korea (South)",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: "Lao People's Democratic Republic",
    LV: "Latvia",
    LB: "Lebanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macao",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshall Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Mexico",
    FM: "Micronesia",
    MD: "Moldova",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Netherlands",
    NC: "New Caledonia",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolk Island",
    MK: "North Macedonia",
    MP: "Northern Mariana Islands",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestine",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    RE: "Reunion",
    RO: "Romania",
    RU: "Russian Federation",
    RW: "Rwanda",
    BL: "Saint Barthelemy",
    SH: "Saint Helena, Ascension and Tristan da Cunha",
    KN: "Saint Kitts and Nevis",
    LC: "Saint Lucia",
    MF: "Saint Martin (French part)",
    PM: "Saint Pierre and Miquelon",
    VC: "Saint Vincent and the Grenadines",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome and Principe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SX: "Sint Maarten (Dutch part)",
    SK: "Slovakia",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    GS: "South Georgia and the South Sandwich Islands",
    SS: "South Sudan",
    ES: "Spain",
    LK: "Sri Lanka",
    SD: "Sudan",
    SR: "Suriname",
    SJ: "Svalbard and Jan Mayen",
    SE: "Sweden",
    CH: "Switzerland",
    SY: "Syrian Arab Republic",
    TW: "Taiwan",
    TJ: "Tajikistan",
    TZ: "Tanzania",
    TH: "Thailand",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad and Tobago",
    TN: "Tunisia",
    TR: "Turkey",
    TM: "Turkmenistan",
    TC: "Turks and Caicos Islands",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates",
    GB: "United Kingdom",
    US: "United States of America",
    UM: "United States Minor Outlying Islands",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Vietnam",
    VG: "Virgin Islands (British)",
    VI: "Virgin Islands (U.S.)",
    WF: "Wallis and Futuna",
    EH: "Western Sahara",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe",
  };
  

  const printInvoice = async () => {
    if (selectedItems.length === 0) {
      Alert.alert('No Items Selected', 'Please select at least one item to create an invoice.');
      return;
    }

    try {
      const invoiceContent = `
        <html>
          <body>
            <div style="text-align: center;">
              <img src="${Base64ImageComponent()}" alt="Logo" style="width: 250px; height: auto;" />
            </div>
            <div style="display: flex; justify-content: flex-end; margin-top: 20px;">
              <div style="border: 1px solid black; padding: 10px; width: 45%;">
                <p style="margin:0"><strong>${customerData.first_name} ${customerData.last_name}</strong></p>
                <p style="margin:0">${customerData.billing.address_1}</p>
                <p style="margin:0">${customerData.billing.postcode}, ${customerData.billing.city}</p>
<p style="margin:0">${countryNames[customerData.billing.country] || customerData.billing.country}</p>
              </div>
            </div>
            <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 20px; margin-bottom: 20px; width: 100%;">
              <div style="border: 1px solid black; padding: 10px; width: 45%; display: flex; align-items: center;">
                <p style="margin: 0;"><strong>Invoice No.:</strong> ${invoiceNo || 'CONFIRM-ORDER-12345'}</p>
              </div>
              <div>
                <p style="margin: 0;"><strong>Antwerp</strong>, ${new Date().toLocaleDateString()}</p>
              </div>
            </div>
            <table style="border-collapse: collapse;width: 100%;">
              <thead>
                <tr>
                  <th style="border: 1px solid black;padding:10px">N° Jewel</th>
                  <th style="border: 1px solid black;padding:10px">Product Name</th>
                  <th style="border: 1px solid black;padding:10px">SKU</th>
                  <th style="border: 1px solid black;padding:10px">Price/Unit</th>
                  <th style="border: 1px solid black;padding:10px">Total Price</th>
                </tr>
              </thead>
              <tbody>
                ${selectedItems.map((item, index) => `
                  <tr>
                    <td style="border: 1px solid black;padding:10px">${index + 1}</td>
                    <td style="border: 1px solid black;padding:10px">${item.name}</td>
                    <td style="border: 1px solid black;padding:10px">${item.sku}</td>
                    <td style="border: 1px solid black;padding:10px">${formatCurrency(item.price)} €</td>
                    <td style="border: 1px solid black;padding:10px">${formatCurrency(item.price)} €</td>
                  </tr>
                `).join('')}
              </tbody>
            </table>
            <div style="display: flex; justify-content: space-between; margin-top: 40px;margin-bottom:20px">
              <div>
                <p style="margin:0"><strong>Payment Method:</strong> ${selectedPaymentMethod}</p>
              </div>
              <div>
                <table style="border-collapse: collapse;">
                  <tbody>
                    <tr>
                      <td style="border: 1px solid black; padding: 10px;"><strong>Total VAT excl.</strong></td>
                      <td style="border: 1px solid black; padding: 10px;">${formatCurrency(totalExcludingVAT)} €</td>
                    </tr>
                    <tr>
                      <td style="border: 1px solid black; padding: 10px;"><strong>VAT (${vatPercentage}%)</strong></td>
                      <td style="border: 1px solid black; padding: 10px;">${formatCurrency(vatAmount)} €</td>
                    </tr>
                    <tr>
                      <td style="border: 1px solid black; padding: 10px;"><strong>Total VAT incl.</strong></td>
                      <td style="border: 1px solid black; padding: 10px;">${formatCurrency(totalIncludingVAT)} €</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div style="margin-bottom:20px">
              <div style="border: 1px solid black; padding: 10px;width:50%">
                <p style="margin:0;font-size:14px">Please pay in € only to: KBC Bank </p>
                <p style="margin:0;font-size:14px">Name: The shamrock-Orogem jewelers </p>
                <p style="margin:0;font-size:14px">IBAN: BE52737001907509 </p>
                <p style="margin:0;font-size:14px">SWIFT: KREDBEBB</p>
              </div>
            </div>
<div style="position: absolute; bottom: 80px; left: 0; width: 100%; text-align: left; font-size: 10px; max-width: 70%;">
  <p style="margin: 0;">Article 8: Jurisdiction and applicable Law</p>
  <p style="margin: 0;">All disputes arising from this here contract are subject to Belgian Law. The Courts of Antwerp are the forum to resolve any such dispute, notwithstanding the seller's right to designate any other jurisdiction if he so chooses.</p>
  <p style="margin: 0;">Note:</p>
  <p style="margin: 0;">*Krachtens de artikelen 2 en 39bis,1° W.BTW wordt geen Belgische BTW in rekening gebracht.</p>
  <p style="margin: 0;">Above mentioned goods will be considered on consignment until full receipt of the payment of this invoice.</p>
  <p style="margin: 0;">The diamonds herein invoiced have been purchased from legitimate sources not involved in funding conflict and in compliance with United Nations Resolutions. The seller hereby guarantees that these diamonds are conflict free, based on personal knowledge and/or written guarantees provided by the supplier of these diamonds.</p>
  <p style="margin: 0;">Note:</p>
  <p style="margin: 0;">**The diamonds herein invoiced are exclusively of natural origin and untreated based on personal knowledge and/or written guarantees provided by the supplier of these diamonds.</p>
</div>
<footer style="position: absolute; bottom: 20px; left: 0; width: 100%; text-align: center; font-size: 12px;">
  <p style="margin: 0;">The Shamrock . Vestingstraat 38 - 2018 Antwerpen - T +32 (0)3 213 27 89 - yannick@juwelenorogem.be</p>
  <p style="margin: 0;">Bank KBC 737-0019075-09 - BTW BE 436 048 553 - H.R. Antwerpen 33.99.44</p>
</footer>
          </body>
        </html>
      `;

      if (Platform.OS === 'web') {
        const win = window.open();
        win.document.write(invoiceContent);
        win.print();
        win.close();
      } else {
        const { uri } = await printAsync({
          html: invoiceContent,
        });
        await printAsync({ uri });
      }
    } catch (error) {
      console.error("Error printing invoice:", error);
    }
  };

  const confirmOrder = async () => {
    console.log('Confirm Order button clicked');
    setIsProcessing(true); // Show "Order Processing"
  
    try {
      console.log('Fetching customer details...');
      const customerDetails = await fetchCustomerDetails(customerData.id);
      console.log('Customer details fetched:', customerDetails);
  
      const shippingAddress = customerDetails.shipping.address_1
        ? {
            first_name: customerDetails.shipping.first_name || customerDetails.billing.first_name,
            last_name: customerDetails.shipping.last_name || customerDetails.billing.last_name,
            company: customerDetails.shipping.company || customerDetails.billing.company,
            address_1: customerDetails.shipping.address_1,
            address_2: customerDetails.shipping.address_2,
            city: customerDetails.shipping.city,
            state: customerDetails.shipping.state,
            postcode: customerDetails.shipping.postcode,
            country: customerDetails.shipping.country,
            email: customerDetails.shipping.email || customerDetails.billing.email,
            phone: customerDetails.shipping.phone || customerDetails.billing.phone,
          }
        : {
            ...customerDetails.billing,
          };
  
      const orderData = {
        customer_id: customerDetails.id,
        payment_method: selectedPaymentMethod.toLowerCase().replace(" ", "_"),
        payment_method_title: selectedPaymentMethod,
        set_paid: isFullyPaid, // Use the isFullyPaid flag
        status: isFullyPaid ? "completed" : "processing", // Set order status
        billing: {
          first_name: customerDetails.billing.first_name,
          last_name: customerDetails.billing.last_name,
          company: "The Shamrock-Orogem Jewelers",
          address_1: "Vestingstraat 38",
          address_2: "",
          city: "Antwerp",
          state: "VAN",
          postcode: "2018",
          country: "BE",
          email: customerDetails.billing.email,
          phone: customerDetails.billing.phone,
        },
        shipping: shippingAddress,
        customer_note: orderNotes,
        line_items: selectedItems.map((item) => {
          if (item.sku === 'TAILOR') {
            // Calculate price excluding VAT
            const priceExclVAT = (parseFloat(item.price) / (1 + parseFloat(vatPercentage) / 100)).toFixed(2);
        
            return {
              name: item.name || 'Tailor-Made Product',
              sku: item.sku,
              quantity: 1,
              total: priceExclVAT, // Total without VAT
              meta_data: [
                { key: 'custom_details', value: item.details || 'N/A' },
              ],
            };
          } else {
            // Regular inventory product
            return {
              product_id: item.id,
              quantity: 1,
            };
          }
        }),
        fee_lines: [
          {
            name: "Finger Size",
            total: "0",
            meta_data: [
              { key: "_wc_checkout_add_on_label", value: fingerSize },
              
              { key: "_wc_checkout_add_on_id", value: "f025dd8" }, // Fixed ID for Finger Size
              { key: "_legacy_fee_key", value: "finger-size" },
            ],
          },
          {
            name: "Engraving text",
            total: "0",
            meta_data: [
              { key: "_wc_checkout_add_on_label", value: engravingText },
              { key: "_wc_checkout_add_on_id", value: "ebddbcb" }, // Fixed ID for Engraving Text
              { key: "_legacy_fee_key", value: "engraving-text" },
            ],
          },
          {
            name: "Engraving",
            total: "0",
            meta_data: [
              { key: "_wc_checkout_add_on_label", value: engravingStyle },
              { key: "_wc_checkout_add_on_id", value: "e696a4c" }, // Fixed ID for Engraving
              { key: "_legacy_fee_key", value: "engraving" },
            ],
          },
        ],
        meta_data: [
          { key: 'from', value: 'Shop' },
          { key: 'source', value: source },
          { key: 'downpayment', value: downpayment },
          { key: 'served_by', value: servedBy },
          { key: 'bag', value: bagNumber },
          { key: 'fully_paid', value: isFullyPaid ? "yes" : "no" },
        ],
      };
  
      console.log('Order data:', JSON.stringify(orderData, null, 2));
  
      const url = `https://juwelenorogem.be/en/wp-json/wc/v3/orders?consumer_key=${process.env.EXPO_PUBLIC_CONSUMER_KEY}&consumer_secret=${process.env.EXPO_PUBLIC_CONSUMER_SECRET}`;
      console.log('Sending order creation request...');
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderData),
      });
  
      if (!response.ok) {
        const errorResponse = await response.json();
        console.error('Order creation failed:', errorResponse);
        throw new Error(errorResponse.message || 'Failed to create order.');
      }
  
      const createdOrder = await response.json();
      console.log('Order created successfully:', createdOrder);
  
      Alert.alert('Success', `Order created successfully with ID: ${createdOrder.id}`);
      navigation.navigate('OrderDetails', { orderId: createdOrder.id, fromConfirmOrder: true });
    } catch (error) {
      console.error('Error confirming order:', error.message || error);
      Alert.alert('Error', `Failed to confirm order: ${error.message}`);
    } finally {
      setIsProcessing(false); // Reset button text
      console.log('Order process completed.');
    }
  };
  
  
  
  
  
  
  
  
  const printCertificate = async (item) => {
    try {
      // Log the full product object to the console
      console.log('Full Product Item:', item);
      
      // Ensure an item is passed
      if (!item || !item.attributes) {
        Alert.alert('No Product Selected', 'Please select a valid product to generate a certificate.');
        return;
      }
  
      // Set the title dynamically
      const title = window.prompt("Enter title for the certificate:", item.name || "Product Certificate");
  
      // Extract product attributes such as metal, carat weight, etc., handling missing data more robustly
      const metal = item.attributes.find(attr => attr.slug === 'pa_metal')?.options[0] || "Unknown Metal";
      const caratWeight = item.attributes.find(attr => attr.slug === 'pa_carat-weight')?.options[0] || "Unknown Carat Weight";
      const stoneQuality = item.attributes.find(attr => attr.slug === 'pa_stone-quality')?.options.join(", ") || "Unknown Quality";
      const giaNumber = item.attributes.find(attr => attr.slug === 'pa_gia-number')?.options[0] || "N/A";
  
      // HTML content for the certificate
      const certificateContent = `
        <html>
          <body style="font-family: Arial, sans-serif;">
            <div style="text-align: center;">
              <h1>${title}</h1>
            </div>
            <div style="margin: 20px;">
              <h2>Product Information</h2>
              <p><strong>Metal:</strong> ${metal}</p>
              <p><strong>Carat Weight:</strong> ${caratWeight}</p>
              <p><strong>Stone Quality:</strong> ${stoneQuality}</p>
              <p><strong>GIA Number:</strong> ${giaNumber}</p>
            </div>
          
          </body>
        </html>
      `;
  
      // Printing the certificate
      if (Platform.OS === 'web') {
        const win = window.open();
        win.document.write(certificateContent);
        win.print();
        win.close();
      } else {
        const { uri } = await printAsync({
          html: certificateContent,
        });
        await printAsync({ uri });
      }
    } catch (error) {
      console.error("Error printing certificate:", error);
      Alert.alert('Error', 'Failed to print the certificate. Please try again.');
    }
  };
  
  const fetchCustomerDetails = async (customerId) => {
    const url = `https://juwelenorogem.be/en/wp-json/wc/v3/customers/${customerId}?consumer_key=${process.env.EXPO_PUBLIC_CONSUMER_KEY}&consumer_secret=${process.env.EXPO_PUBLIC_CONSUMER_SECRET}`;
    const response = await fetch(url);
  
    if (!response.ok) {
      throw new Error(`Failed to fetch customer details. HTTP status ${response.status}`);
    }
  
    return response.json();
  };

  
  return (
    <View style={styles.container2}>
      <ScrollView style={styles.container} contentContainerStyle={styles.scroll}>
        <Text style={styles.title}>Order Summary</Text>

       

       

       

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Customer Details</Text>
          <Text style={styles.text}>Name: {customerData?.first_name} {customerData?.last_name}</Text>
          <Text style={styles.text}>Email: {customerData?.email}</Text>
          <Text style={styles.text}>
  Address: {customerData?.billing.address_1}, {customerData?.billing.city}, {countryNames[customerData?.billing.country] || customerData?.billing.country}
</Text>
          <Text style={styles.text}>Phone: {customerData?.billing.phone}</Text>

        </View>
        
        <View style={styles.section}><View style={styles.spacer}></View>
          <Text style={styles.sectionTitle}>Selected Products</Text>

          {selectedItems.map((item, index) => {
  // Log each item for debugging
  console.log(`Item at index ${index}:`, item);

  // Use fallback values for missing properties
  const imageUrl = item.images?.[0]?.src || 'https://via.placeholder.com/80';
  const name = item.name || 'Unnamed Product';
  const sku = item.sku || 'N/A';
  const price = item.price ? formatCurrency(item.price) : '0.00';

  return (
    <View key={item.id || index} style={styles.productRow}>
      <Image
        source={{ uri: imageUrl }}
        style={styles.productImage}
        resizeMode="contain"
      />
      <View style={styles.productDetails}>
        <Text style={styles.productText}>{index + 1}. {name}</Text>
        <Text style={styles.productText}>SKU: {sku}</Text>
        <Text style={styles.productPrice}>{price}€</Text>
      </View>
      <TouchableOpacity 
        style={styles.button} 
        onPress={() => navigation.navigate('Print Certificate', { selectedItem: item })}
      >
        <Text style={styles.buttonText}>Print Certificate</Text>
      </TouchableOpacity>
    </View>
  );
})}

        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Total Amounts</Text>
          <Text style={styles.text}>Total VAT excl.: {formatCurrency(totalExcludingVAT)} €</Text>
          <Text style={styles.text}>VAT ({vatPercentage}%): {formatCurrency(vatAmount)} €</Text>
          <Text style={styles.totalAmount}>Total VAT incl.: {formatCurrency(totalIncludingVAT)} €</Text>
        </View>

        <View style={styles.section}><View style={styles.spacer}></View>
        <View style={styles.productRow}>
        <View style={styles.section2}>
  <Text style={styles.sectionTitle}>Payment Method</Text>
  <Picker
    selectedValue={selectedPaymentMethod}
    onValueChange={(itemValue) => setSelectedPaymentMethod(itemValue)}
    style={styles.picker}
  >
    <Picker.Item label="Cash" value="Cash" />
    <Picker.Item label="Credit Card" value="Credit Card" />
    <Picker.Item label="Bank Transfer" value="Bank Transfer" />
  </Picker>
</View>

<View style={styles.section2}>
  <Text style={styles.sectionTitle}>Source</Text>
  <Picker
    selectedValue={source}
    onValueChange={(value) => setSource(value)}
    style={styles.picker}
  >
    <Picker.Item label="Online searching" value="Online searching" />
    <Picker.Item label="Advertising" value="Advertising" />
    <Picker.Item label="Friends" value="Friends" />
    <Picker.Item label="Already Customer" value="Already Customer" />
  </Picker>
</View>

<View style={styles.section2}>
  <Text style={styles.sectionTitle}>Served By</Text>
  <Picker
    selectedValue={servedBy}
    onValueChange={(value) => setServedBy(value)}
    style={styles.picker}
  >
    <Picker.Item label="Yannick" value="Yannick" />
    <Picker.Item label="Veronique" value="Veronique" />
    <Picker.Item label="Karin" value="Karin" />
    <Picker.Item label="Nikol" value="Nikol" />
    <Picker.Item label="Viktoriia" value="Viktoriia" />
    <Picker.Item label="Riccardo" value="Riccardo" />
  </Picker>
</View>
</View></View>


<View style={styles.section}>
<View style={styles.productRow}>


<View style={styles.section2}>
  <Text style={styles.sectionTitle}>Downpayment (€)</Text>
  <TextInput
    style={styles.input}
    placeholder="Enter Downpayment"
    value={downpayment}
    keyboardType="numeric"
    onChangeText={(value) => setDownpayment(value)}
  />
</View>



<View style={styles.section2}>
  <Text style={styles.sectionTitle}>Bag Number</Text>
  <TextInput
    style={styles.input}
    placeholder="Enter Bag Number"
    value={bagNumber}
    onChangeText={(value) => setBagNumber(value)}
  />
</View>

<View style={styles.section2}>
  <Text style={styles.sectionTitle}>Fully Paid</Text>
  <TouchableOpacity
    style={[styles.checkbox, isFullyPaid ? styles.checked : styles.unchecked]}
    onPress={() => setIsFullyPaid(!isFullyPaid)}
  >
    <Text style={styles.checkboxLabel}>{isFullyPaid ? "Yes" : "No"}</Text>
  </TouchableOpacity>
</View>

</View></View>
<View style={styles.section}>
  <Text style={styles.sectionTitle}>Order Notes</Text>
  <TextInput
    style={[styles.input, { height: 100, textAlignVertical: 'top' }]} // Multi-line input
    placeholder="Enter any notes for the order..."
    multiline
    numberOfLines={4}
    value={orderNotes}
    onChangeText={(text) => setOrderNotes(text)}
  />
</View>
<View style={styles.section}>
  <Text style={styles.sectionTitle}>Invoice Number</Text>
  <TextInput
    style={styles.input}
    placeholder="Enter Invoice Number"
    value={invoiceNo}
    onChangeText={(text) => setInvoiceNo(text)}
  />
</View>
<View style={styles.section}>
<View style={styles.productRow}>


<View style={styles.section2}>
        <Text style={styles.sectionTitle}>Finger Size</Text>
        <Picker
          selectedValue={fingerSize}
          onValueChange={(value) => setFingerSize(value)}
          style={styles.picker}
        >
          {Array.from({ length: 21 }, (_, i) => (46 + i)).map((size) => (
            <Picker.Item key={size} label={`${size}`} value={`${size}`} />
          ))}
          <Picker.Item label="I don't know my ring size" value="I don't know my ring size" />
        </Picker>
      </View>

      <View style={styles.section2}>
        <Text style={styles.sectionTitle}>Engraving Style</Text>
        <Picker
          selectedValue={engravingStyle}
          onValueChange={(value) => setEngravingStyle(value)}
          style={styles.picker}
        >
          <Picker.Item label="Straight font" value="Straight font" />
          <Picker.Item label="Cursive font" value="Cursive font" />
        </Picker>
      </View>

      <View style={styles.section2}>
        <Text style={styles.sectionTitle}>Engraving Text</Text>
        <TextInput
          style={styles.input}
          placeholder="Enter engraving text"
          value={engravingText}
          onChangeText={(text) => setEngravingText(text)}
        />
      </View> 
      </View>
      </View>
<View style={styles.actions}>
  <TouchableOpacity style={styles.button} onPress={printInvoice}>
    <Text style={styles.actionButtonText}>Print Invoice</Text>
  </TouchableOpacity>
  <TouchableOpacity style={styles.button} onPress={confirmOrder} disabled={isProcessing}>
        <Text style={styles.buttonText}>{isProcessing ? "Order Processing..." : "Confirm Order"}</Text>
      </TouchableOpacity>
</View>

      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff', // Light background
    paddingHorizontal: 20,
    width: '100%',
    alignSelf: 'center',
  },
  container2: {
    flex: 1,
    backgroundColor: '#fff', // Light background
    paddingHorizontal: 20,
    alignSelf: 'center',
    width: '100%',
  },
  title: {
    fontSize: 24,
    color: '#000', // Dark text for contrast
    textAlign: 'center',
    marginVertical: 20,
  },
  section: {
    marginBottom: 20,
    width: '90%',
    alignSelf: 'center',
  },
  section2: {
    marginBottom: 20,
    width: '30%',
    alignSelf: 'center',
  },
  sectionCustomer: {
    marginBottom: 20,
    width: '90%',
    alignSelf: 'center',
    borderWidth:1,
    borderStyle:"solid",
    padding:10
  },
  sectionTitle: {
    fontSize: 26,
    color: '#333', // Slightly darker text for headings
    marginBottom: 10,
  },
  text: {
    color: '#555', // Neutral gray for body text
    fontSize: 16,
    marginBottom: 5,
  },
  productContainer: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd', // Subtle border for light theme
    paddingBottom: 5,
    marginBottom: 10,
  },
  productText: {
    color: '#333', // Darker text for product details
    fontSize: 16,
  },
  totalAmount: {
    color: '#000', // Bold dark color for emphasis
    fontSize: 18,
    fontWeight: 'bold',
  },
  button: {
    backgroundColor: '#007BFF', // Light blue button color
    padding: 15,
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 10,
  },
  buttonText: {
    color: '#fff', // White text for contrast
    fontSize: 18,
  },
  input: {
    borderWidth: 1,
    borderColor: '#ccc', // Light gray border
    color: '#000', // Dark text for input fields
    padding: 10,
    borderRadius: 5,
    backgroundColor: '#f9f9f9', // Subtle background for inputs
  },
  checkbox: {
    padding: 10,
    borderWidth: 1,
    borderColor: '#ccc', // Light border
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    width: 100,
  },
  checked: {
    backgroundColor: '#28a745', // Green for checked state
  },
  unchecked: {
    backgroundColor: '#dc3545', // Red for unchecked state
  },
  checkboxLabel: {
    color: '#000', // Dark text for checkbox labels
    fontSize: 16,
  },
  scrollView: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingVertical: 20,
  },

  spacer: {
    marginBottom: 20, // Add some space between rows
    borderBottomWidth: 1, // Add a bottom border
    borderBottomColor: '#ddd', // Subtle border color
    paddingBottom: 10, // Padding at the bottom of the row
  },
  productDetails: {
    flex: 1, // Take up available space for the details
    marginRight: 10, // Space between details and the button
  },
  picker: {
    height: 50,
    borderColor: '#ccc',
    borderWidth: 1,
    backgroundColor: '#f9f9f9',
    borderRadius: 5,
    marginBottom: 10,
  },
  
  actions: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  
  actionButton: {
    backgroundColor: '#007BFF',
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderRadius: 5,
    alignItems: 'center',
    flex: 1,
    marginHorizontal: 5,
  },
  
  actionButtonText: {
    color: '#fff',
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  productImage: {
    width: 80, // Fixed width for the image
    height: 80, // Fixed height for the image
    marginRight: 10, // Space between the image and details
    borderRadius: 5, // Optional: round corners
    backgroundColor: '#f0f0f0', // Placeholder background color
  },
  
  productRow: {
    flexDirection: 'row', // Arrange items in a row
    justifyContent: 'space-between', // Space between sections
    alignItems: 'center', // Align items vertically
    marginBottom: 10, // Add space between rows
    borderBottomWidth: 1, // Add a bottom border
    borderBottomColor: '#ddd', // Subtle border color
    paddingBottom: 10, // Padding at the bottom of the row
  },
});


export default ConfirmOrder;
