import React, { useEffect, useState } from 'react';
import { View, Text, FlatList, ActivityIndicator, StyleSheet, TouchableOpacity, Alert, ScrollView } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useFocusEffect } from '@react-navigation/native';

const ConsignmentsList = () => {
  const [consignments, setConsignments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [resellers, setResellers] = useState({});
  const [deletingConsignment, setDeletingConsignment] = useState(null); 
  const navigation = useNavigation();
  const [selectedConsignment, setSelectedConsignment] = useState(null);

  const SHOP_ID = 697; 
  useFocusEffect(
    React.useCallback(() => {
      fetchConsignments(); // Refresh the consignment list
    }, [])
  );
  const fetchConsignments = async () => {
    setIsLoading(true);
    try {

      const url = `
      https://stripe-server-node-lbl0p.kinsta.app/proxy?url=${encodeURIComponent(`https://juwelenorogem.be/en/wp-json/wp/v2/consignments?consumer_key=${process.env.EXPO_PUBLIC_CONSUMER_KEY}&consumer_secret=${process.env.EXPO_PUBLIC_CONSUMER_SECRET}&_=${new Date().getTime()}`)}
      `;
      const response = await fetch(url);



      if (!response.ok) {
        throw new Error(`HTTP status ${response.status}`);
      }

      const data = await response.json();
  
      const updatedConsignments = await Promise.all(
        data.map(async (consignment) => {
          const products = consignment.acf.products || [];
          const customProducts = consignment.acf.custom_products || []; // Fetch custom products
          const shippingFee = parseFloat(consignment.acf.shipping_fee || 0); // Fetch shipping fee
          let totalPrice = 0;
  
          // Calculate total price for standard products
          const fetchedProducts = await Promise.all(
            products.map(async (productId) => {
              const productUrl = `https://stripe-server-node-lbl0p.kinsta.app/proxy?url=${encodeURIComponent(
                `https://juwelenorogem.be/en/wp-json/wc/v3/products/${productId}?consumer_key=${process.env.EXPO_PUBLIC_CONSUMER_KEY}&consumer_secret=${process.env.EXPO_PUBLIC_CONSUMER_SECRET}`
              )}`;
              const productResponse = await fetch(productUrl);
              if (!productResponse.ok) {
                console.warn(`Failed to fetch product ${productId}`);
                return null;
              }
              const productData = await productResponse.json();
              const numberusdAttr = productData.attributes.find((attr) => attr.slug === 'pa_numberusd');
              const numberusd = numberusdAttr ? parseFloat(numberusdAttr.options[0]) || 0 : 0;
  
              totalPrice += numberusd;
              return productData;
            })
          );
  
          // Calculate total price for custom products
          customProducts.forEach((customProduct) => {
            const customPrice = parseFloat(customProduct.price || 0);
            totalPrice += customPrice;
          });
  
          // Add shipping fee to total price
          totalPrice += shippingFee;
  
          return {
            ...consignment,
            totalPrice: formatPrice(totalPrice),
            totalProducts: products.length + customProducts.length, 
          };
        })
      );
  
      // Fetch reseller names after fetching consignments
      fetchResellerNames(updatedConsignments);
    } catch (error) {
      console.error("Error fetching consignments:", error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  

  const formatPrice = (price) => {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
    }).format(price);
  };
  
  

  const fetchResellerNames = async (consignmentsData) => {
    const updatedConsignments = await Promise.all(
      consignmentsData.map(async (consignment) => {
        const resellerId = consignment.acf?.reseller;
        if (!resellerId) return { ...consignment, resellerName: "Unknown Reseller" };

        if (resellers[resellerId]) {
          return { ...consignment, resellerName: resellers[resellerId] };
        }

        try {
        const url = `https://stripe-server-node-lbl0p.kinsta.app/proxy?url=${encodeURIComponent(
          `https://juwelenorogem.be/en/wp-json/wc/v3/customers/${resellerId}?consumer_key=${process.env.EXPO_PUBLIC_CONSUMER_KEY}&consumer_secret=${process.env.EXPO_PUBLIC_CONSUMER_SECRET}`
        )}`;
        const response = await fetch(url);         

          if (!response.ok) {
            console.warn(`Failed to fetch reseller ${resellerId}: ${response.status}`);
            return { ...consignment, resellerName: `Unknown Reseller (${resellerId})` };
          }

          const resellerData = await response.json();
          const resellerName =
            (resellerData.first_name && resellerData.last_name)
              ? `${resellerData.first_name} ${resellerData.last_name}`
              : resellerData.username || "Unnamed Reseller";

          setResellers((prev) => ({ ...prev, [resellerId]: resellerName }));
          return { ...consignment, resellerName };
        } catch (error) {
          console.error(`Error fetching reseller ${resellerId}:`, error);
          return { ...consignment, resellerName: `Unknown Reseller (${resellerId})` };
        }
      })
    );

    setConsignments(updatedConsignments);
  };

  const deleteConsignment = async (consignment) => {
    console.log('Deleting consignment:', consignment.id); // Debugging log

    setDeletingConsignment(consignment.id);
    try {
      // Reassign products to SHOP_ID
      for (const productId of consignment.acf.products || []) {
        const updateUrl = `https://juwelenorogem.be/wp-json/wc/v3/products/${productId}`;
        const response = await fetch(updateUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${await AsyncStorage.getItem('jwtToken')}`,
          },
          body: JSON.stringify({
            meta_data: [
              { key: 'location', value: SHOP_ID },
            ],
          }),
        });

        if (!response.ok) {
          throw new Error(`Failed to reassign product ${productId}`);
        }
      }

      // Delete consignment
      const deleteUrl = `https://juwelenorogem.be/wp-json/wp/v2/consignments/${consignment.id}`;
      const deleteResponse = await fetch(deleteUrl, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await AsyncStorage.getItem('jwtToken')}`,
        },
      });

      if (!deleteResponse.ok) {
        throw new Error(`Failed to delete consignment ${consignment.id}`);
      }

      fetchConsignments(); // Refresh the consignments list
    } catch (error) {
      console.error('Error deleting consignment:', error.message || error);
    } finally {
      setDeletingConsignment(null);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString || dateString.length !== 8) return dateString;
    return `${dateString.slice(6, 8)}/${dateString.slice(4, 6)}/${dateString.slice(0, 4)}`;
  };

  useEffect(() => {
    fetchConsignments();
  }, []);

  const renderConsignmentItem = ({ item, index }) => (
    <View style={styles.listItem}>
      <View style={styles.itemHeader}>
        <Text style={styles.dateText}>{formatDate(item.acf.date)}</Text>
        <TouchableOpacity
          onPress={() => setSelectedConsignment(item)}
          style={styles.deleteButton}
        >
          <Text style={styles.deleteButtonText}>
            {deletingConsignment === item.id ? 'Deleting...' : 'Delete'}
          </Text>
        </TouchableOpacity>
      </View>
      <TouchableOpacity
        onPress={() => navigation.navigate("Consignment Details", { consignment: item })}
      >
        <View style={styles.itemBody}>
          <Text style={styles.indexText}># {index + 1}</Text>
          <Text style={styles.resellerText}>Reseller: {item.resellerName}</Text>
          <Text style={styles.productCountText}>Products: {item.totalProducts}</Text>
          <Text style={styles.totalPriceText}>Total Price: {item.totalPrice}</Text>
        </View>
      </TouchableOpacity>
    </View>
  );
  
  

  if (isLoading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="#0000ff" />
      </View>
    );
  }

  if (error) {
    return (
      <View style={styles.errorContainer}>
        <Text style={styles.errorText}>Error: {error}</Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
{selectedConsignment && (
  <View style={styles.confirmationModal}>
    <Text>Are you sure you want to delete this consignment and reassign products?</Text>
    <TouchableOpacity
      onPress={() => {
        deleteConsignment(selectedConsignment);
        setSelectedConsignment(null);
      }}
      style={styles.modalButton}
    >
      <Text style={styles.modalButtonText}>Delete</Text>
    </TouchableOpacity>
    <TouchableOpacity
      onPress={() => setSelectedConsignment(null)}
      style={styles.modalButton}
    >
      <Text style={styles.modalButtonText}>Cancel</Text>
    </TouchableOpacity>
  </View>
)}
                                  <View style={{width:1080, alignSelf:"center", backgroundColor: '#fff', flex:1,
    borderRadius: 8,
    padding: 15,
    marginVertical: 8,


    elevation: 3,}}>

      <FlatList
        data={consignments.sort((a, b) => new Date(b.date) - new Date(a.date))}
        keyExtractor={(item) => item.id.toString()}
        renderItem={renderConsignmentItem}
        contentContainerStyle={styles.listContainer}

      /> 
    </View>    </View>

  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f9f9f9',
    padding: 10,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorText: {
    color: 'red',
    fontSize: 16,
  },

  listItem: {
    backgroundColor: '#fff',
    borderRadius: 8,
    padding: 15,
    marginVertical: 8,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 3,
  },
  itemHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  indexText: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#333',
  },
  dateText: {
    fontSize: 14,
    color: '#666',
  },
  itemBody: {
    marginTop: 5,
  },
  resellerText: {
    fontSize: 16,
    color: '#555',
  },
  productCountText: {
    fontSize: 14,
    color: '#777',
    marginTop: 5,
  },
  deleteButton: {
    backgroundColor: '#ff4d4d',
    padding: 10,
    borderRadius: 5,
  },
  deleteButtonText: {
    color: '#fff',
    fontWeight: 'bold',
  },
  confirmationModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: [{ translateX: -150 }, { translateY: -100 }], // Adjust the values as per your modal size
    width: 300,
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 6,
    elevation: 5,
    zIndex: 1000,
  },
  modalButton: {
    backgroundColor: '#000',
    padding: 10,
    borderRadius: 5,
    alignItems: 'center',
    marginTop: 10,
  },
  modalButtonText: {
    color: '#fff',
    fontWeight: 'bold',
  },
  totalPriceText: {
    fontSize: 14,
    color: '#333',
    marginTop: 5,
    fontWeight: 'bold',
    textAlign: 'right', // Optional, for better alignment
  },
  listContainer: {
    flexGrow: 1, // Ensures the FlatList's content scrolls properly
    paddingBottom: 20, // Optional padding at the bottom
  },
});

export default ConsignmentsList;
