import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, ActivityIndicator, ScrollView, Image, Linking } from 'react-native';

const OrderDetails = ({ route }) => {
  const { orderId, fromConfirmOrder } = route.params;
  const [order, setOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const extractMetaData = (metaData, key) => {
    const meta = metaData.find((item) => item.key === key);
    return meta ? meta.value : 'N/A';
  };

  const extractFeeData = (fees, key) => {
    for (const fee of fees) {
      const meta = fee.meta_data.find((item) => item.key === key);
      if (meta) {
        return meta.value;
      }
    }
    return 'N/A';
  };

  const fetchProductLink = async (productId) => {
    try {
      const response = await fetch(
        `https://juwelenorogem.be/en/wp-json/wc/v3/products/${productId}?consumer_key=${process.env.EXPO_PUBLIC_CONSUMER_KEY}&consumer_secret=${process.env.EXPO_PUBLIC_CONSUMER_SECRET}`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch product details');
      }
      const productData = await response.json();
      return productData.permalink || 'N/A';
    } catch (error) {
      console.error(`Error fetching product ${productId}:`, error.message || error);
      return 'N/A';
    }
  };
  const extractOrderMetaData = (metaData, keys) => {
    const values = {};
    keys.forEach((key) => {
      const meta = metaData.find((item) => item.key === key);
      values[key] = meta ? meta.value : 'N/A';
    });
    return values;
  };
  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        console.log('Fetching order details...');
        const response = await fetch(
          `https://juwelenorogem.be/en/wp-json/wc/v3/orders/${orderId}?consumer_key=${process.env.EXPO_PUBLIC_CONSUMER_KEY}&consumer_secret=${process.env.EXPO_PUBLIC_CONSUMER_SECRET}&_embed=true`
        );
        if (!response.ok) {
          throw new Error('Failed to fetch order details');
        }
        const data = await response.json();
        console.log('Full Order Object:', JSON.stringify(data, null, 2));
  
        const fingersize = extractFeeData(
          data.fee_lines.filter((fee) => fee.name === 'Finger Size'),
          '_wc_checkout_add_on_label'
        );
        
        const engravingText = extractFeeData(
          data.fee_lines.filter((fee) => fee.name === 'Engraving text'),
          '_wc_checkout_add_on_label'
        );

const engravingFont = extractFeeData(
  data.fee_lines.filter((fee) => fee.name === 'Engraving'),
  '_wc_checkout_add_on_label'
);

const metaKeys = ['downpayment', 'from', 'source', 'served_by', 'bag', 'fully_paid'];
const orderMeta = extractOrderMetaData(data.meta_data, metaKeys);
  
        // Fetch product links
        const lineItemsWithLinks = await Promise.all(
          data.line_items.map(async (item) => {
            const permalink = await fetchProductLink(item.product_id);
                // Add logic to include the personal note for the TAILOR SKU
    const personalNote = item.sku === 'TAILOR'
    ? extractMetaData(item.meta_data, 'custom_details')
    : null;
            return { ...item, permalink, personalNote };
          })
        );
  
        setOrder({
          ...data,
          fingersize,
          engravingText,
          engravingFont,
          line_items: lineItemsWithLinks,
          orderMeta,  // Include orderMeta in the final order object

        });
      } catch (error) {
        console.error('Error fetching order details:', error.message || error);
      } finally {
        setIsLoading(false);
      }
    };
  
    // Fetch order details if `order` is null (to ensure it doesn't refetch unnecessarily)
    if (!order) {
      fetchOrderDetails();
    }
  }, [orderId, fromConfirmOrder, order]);

  if (isLoading) {
    return (
      <View style={styles.loaderContainer}>
        <ActivityIndicator size="large" color="#0000ff" />
        <Text>Loading order details...</Text>
      </View>
    );
  }

  if (!order) {
    return (
      <View style={styles.errorContainer}>
        <Text style={styles.errorText}>Failed to load order details.</Text>
      </View>
    );
  }




  return (
    <ScrollView style={styles.container}>
      <Text style={styles.title}>Order Details</Text>
      <View style={{width:800, alignSelf:"center", backgroundColor: '#fff',
    borderRadius: 8,
    padding: 15,
    marginVertical: 8,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 3,}}>


      <View style={{ width: 800, alignSelf: "center", backgroundColor: "#fff", borderRadius: 8, padding: 15, marginVertical: 8, shadowColor: "#000", shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.1, shadowRadius: 5, elevation: 3 }}>
  <View style={styles.card}>
    <Text style={styles.cardTitle}>General Information</Text>
    <Text style={styles.text}>Order ID: {order.id}</Text>
    <Text style={styles.text}>Status: {order.status}</Text>
    <Text style={styles.text}>Payment Method: {order.payment_method_title}</Text>
    <Text style={styles.text}>Date: {new Date(order.date_created).toLocaleDateString()}</Text>
  </View>

  <View style={styles.card}>
    <Text style={styles.cardTitle}>Billing Information</Text>
    <Text style={styles.text}>
      Name: {order.billing.first_name} {order.billing.last_name}
    </Text>
    <Text style={styles.text}>Email: {order.billing.email}</Text>
    <Text style={styles.text}>
      Address: {order.billing.address_1}, {order.billing.city}, {order.billing.postcode}
    </Text>
    <Text style={styles.text}>Phone: {order.billing.phone}</Text>
  </View>

  <View style={styles.card}>
    <Text style={styles.cardTitle}>Shipping Information</Text>
    {order.shipping ? (
      <>
        <Text style={styles.text}>
          Name: {order.shipping.first_name} {order.shipping.last_name}
        </Text>
        <Text style={styles.text}>
          Address: {order.shipping.address_1}, {order.shipping.city}, {order.shipping.postcode}
        </Text>
        <Text style={styles.text}>Phone: {order.shipping.phone || 'N/A'}</Text>
      </>
    ) : (
      <Text style={styles.text}>No shipping information available</Text>
    )}
  </View>
</View>

  

<View style={styles.card}>
  <Text style={styles.cardTitle}>Products</Text>
  {order.line_items.map((item) => {
    // Calculate the VAT-inclusive unit price inside the map
    const vatInclusiveUnitPrice = (parseFloat(item.subtotal) + parseFloat(item.subtotal_tax)) / item.quantity;

    return (
      <View key={item.id} style={styles.productRow}>
        <Image source={{ uri: item.image.src }} style={styles.productImage} />
        <View style={styles.productDetails}>
          <Text style={styles.productText}>Name: {item.name}</Text>
          <Text style={styles.productText}>Quantity: {item.quantity}</Text>
          <Text style={styles.productText}>
            Unit Price (incl. VAT): €{vatInclusiveUnitPrice.toFixed(2)}
          </Text>
          {item.sku === 'TAILOR' && item.personalNote && (
            <Text style={styles.productText}>Personal Note: {item.personalNote}</Text>
          )}
          <Text
            style={styles.link}
            onPress={() => Linking.openURL(item.permalink)}
          >
            View Product
          </Text>
        </View>
      </View>
    );
  })}
</View>
      <View style={styles.card}>
  <Text style={styles.cardTitle}>Order Summary</Text>
  <Text style={styles.text}>Total VAT excl.: €{(order.total - order.total_tax).toFixed(2)}</Text>
  <Text style={styles.text}>VAT Amount: €{order.total_tax}</Text>
  <Text style={styles.totalAmount}>Total (incl. VAT): €{order.total}</Text>
</View>

      <View style={styles.card}>
        <Text style={styles.cardTitle}>Size and Engraving</Text>
        <Text style={styles.text}>Finger Size: {order.fingersize}</Text>
        <Text style={styles.text}>Engraving Text: {order.engravingText}</Text>
        <Text style={styles.text}>Engraving Font: {order.engravingFont}</Text>
      </View>     
      
      <View style={styles.card}>
  <Text style={styles.cardTitle}>Order Meta Information</Text>
  <Text style={styles.text}>Downpayment: {order.orderMeta.downpayment} €</Text>
  <Text style={styles.text}>From: {order.orderMeta.from}</Text>
  <Text style={styles.text}>Source: {order.orderMeta.source}</Text>
  <Text style={styles.text}>Served By: {order.orderMeta.served_by}</Text>
  <Text style={styles.text}>Bag Number: {order.orderMeta.bag}</Text>
  <Text style={styles.text}>Fully Paid: {order.orderMeta.fully_paid}</Text>
</View>
       </View>

    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f8f9fa',
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  title: {
    fontSize: 28,
    fontWeight: 'bold',
    color: '#343a40',
    marginBottom: 20,
    textAlign: 'center',
  },
  loaderContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorText: {
    fontSize: 18,
    color: 'red',
  },
  card: {
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 15,
    marginBottom: 15,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 4,
    shadowOffset: { width: 0, height: 2 },
    elevation: 3,
  },
  cardTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#495057',
    marginBottom: 10,
  },
  text: {
    fontSize: 16,
    color: '#6c757d',
    marginBottom: 5,
  },
  productRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15,
    borderBottomWidth: 1,
    borderBottomColor: '#dee2e6',
    paddingBottom: 10,
  },
  productImage: {
    width: 80,
    height: 80,
    marginRight: 10,
  },
  productDetails: {
    flex: 1,
  },
  productText: {
    fontSize: 16,
    color: '#343a40',
  },
  link: {
    fontSize: 16,
    color: '#007bff',
    textDecorationLine: 'underline',
  },
});

export default OrderDetails;
