import React, { useContext, useEffect } from 'react';
import { ScrollView, StyleSheet, View, Image, TouchableOpacity, Text, Alert } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import AuthContext from '../AuthContext'; // Importiamo l'AuthContext
const proxyUrl = 'https://stripe-server-node-lbl0p.kinsta.app/proxy?url=';
const apiUrl = 'https://juwelenorogem.be/en/wp-json/wc/v3/products';
const consumerKey = process.env.EXPO_PUBLIC_CONSUMER_KEY;
const consumerSecret = process.env.EXPO_PUBLIC_CONSUMER_SECRET;

const PosHome = ({ navigation }) => {
  useEffect(() => {
    // Prefetch the inventory data in the background
    prefetchInventory();
  }, []);

  const prefetchInventory = async () => {
    const url = `${proxyUrl}${encodeURIComponent(`${apiUrl}?consumer_key=${consumerKey}&consumer_secret=${consumerSecret}&orderby=price&order=asc&per_page=100&page=1`)}`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Failed to prefetch inventory');
      }
      await response.json(); // Cache the data by fetching it
      console.log('Inventory prefetched successfully');
    } catch (error) {
      console.error('Error prefetching inventory:', error);
    }
  };

  return (
    <View style={styles.container}>
      
      <ScrollView style={styles.scroll} contentContainerStyle={styles.scrollcontainer}>
        <Image source={require('../assets/logo.jpg')} style={styles.logo} />
        
        <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('New Order')}>
          <Text style={styles.text}>REGISTER NEW SALE</Text>
        </TouchableOpacity>
      
      </ScrollView>
    
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
  },
  scrollcontainer:{
    justifyContent: 'center',
flex:1

  },

  
  logo: {
    width: 300,
    height: 200,
    resizeMode: 'contain',
    marginTop: 50,
    marginBottom: 20,
  },
  text:{
    color:"black",
    fontSize:18
  },
  button:{
    borderWidth:1,
    borderColor:"black",
    padding:20,
    alignItems:"center",
    marginBottom:20,
    backgroundColor:"#c6c6c62b"
  },
  logoutButton: {
    position: 'absolute',
    top: 60, // Adjusted position
    right: 40,
    backgroundColor: 'red',
    padding: 10,
    borderRadius: 5,
  },
  logoutText: {
    color: 'white',
    fontSize: 16,
  }
});

export default PosHome;
